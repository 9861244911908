import { api } from "@enerbit/base";
import { ISolarInformationForm } from "../models/form/SolarInformationForm";

export const updateState = async (
    stateId: string,
    estate: ISolarInformationForm,
) => {
    try {
        await api.patch(`/accounts/estates/${stateId}/`, {
            address: estate.address,
            state: estate.state,
            city: estate.city,
            geolocalization: {
                altitude: estate.altitude,
                longitude: estate.longitude,
                latitude: estate.latitude,
                plus_code: estate.plus_code,
            },
        });
    } catch (error) {
        throw error;
    }
};

export const updateDetails = async (
    solarServiceId: string,
    details: {
        capacity: number;
        predictive_behavior_details?: { [key: string]: number };
    },
) => {
    try {
        await api.patch(`/solar-ops/solar-details/${solarServiceId}/`, {
            capacity_peak: details.capacity,
            ...(details.predictive_behavior_details && {
                predictive_behavior_details:
                    details.predictive_behavior_details,
            }),
        });
    } catch (error) {
        throw error;
    }
};
