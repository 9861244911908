import {
    Box,
    Grid,
    TextField,
    Typography,
    dayjs,
    enerbitColors,
} from "@enerbit/base";
import { useFormContext } from "react-hook-form";
export type CardTitleDynamicTableProps = {
    title: string;
};

const CardTitleDynamicTable = ({ title }: CardTitleDynamicTableProps) => {
    return (
        <Box
            sx={{
                backgroundColor: enerbitColors.neutral[100],
                padding: "12px",
                borderRadius: "8px",
            }}
        >
            <Typography variant="body1" textAlign="center" fontWeight="bold">
                {title}
            </Typography>
        </Box>
    );
};

export const MonthlyEstimates = () => {
    const { watch, register, setValue } = useFormContext();

    return (
        <Box>
            <Typography
                color="primary"
                sx={{ fontWeight: 700, fontSize: "16px", mb: "2px" }}
            >
                4. Estimaciones
            </Typography>
            <Box
                mt={2}
                sx={{
                    display: "flex",
                    alignItems: "center",
                }}
            >
                <Grid container spacing={2} mr={2}>
                    <Grid item xs={6}>
                        <CardTitleDynamicTable title="Meses" />
                    </Grid>
                    <Grid item xs={6}>
                        <CardTitleDynamicTable title="kWp" />
                    </Grid>
                </Grid>
            </Box>

            {Object.entries(watch("estimates")).map(([month], index) => (
                <Box
                    key={index}
                    mt={2}
                    sx={{ display: "flex", alignItems: "center" }}
                >
                    <Grid container spacing={2} mr={2}>
                        <Grid item xs={6}>
                            <TextField
                                disabled
                                fullWidth
                                className="TextField-without-border-radius"
                                variant="outlined"
                                value={dayjs().month(index).format("MMMM")}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                type="number"
                                fullWidth
                                className="TextField-without-border-radius"
                                variant="outlined"
                                {...register(`estimates.${month}`, {
                                    valueAsNumber: true,
                                })}
                                onChange={(e) =>
                                    setValue(
                                        `estimates.${month}`,
                                        e.target.value,
                                    )
                                }
                            />
                        </Grid>
                    </Grid>
                </Box>
            ))}
        </Box>
    );
};
