import { dayjs } from "@enerbit/base";
import { ISolarDetails } from "../models/solar-service/Solar";

export const getInitialEstimatesValues = (details?: ISolarDetails) => {
    let historicData = {};
    if (details) {
        for (let index = 0; index < 12; index++) {
            const month = dayjs()
                .month(index)
                .locale("en")
                .format("MMMM")
                .toLowerCase();
            historicData = {
                ...historicData,
                [month]: details.predictive_behavior_details?.[month] ?? 0,
            };
        }
    }
    return historicData;
};
