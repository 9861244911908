import { dayjs, enerbitColors } from "@enerbit/base";
import "dayjs/locale/es";
import { convertToEnergy } from "../classes/Energy";
interface ILabelText {
    period: string;
    start?: Date;
    value?: number;
}
interface IAvrLabelText {
    period: string;
    avgEnergySelfconsumption?: number;
    start?: Date;
    end?: Date;
}
dayjs.locale("es");
export const getLabelText = ({ period, start, value = 0 }: ILabelText) => {
    if (!start) {
        return (
            <span
                style={{
                    color: enerbitColors.primary.main,
                    fontWeight: "bold",
                }}
            >
                Selecciona un valor
            </span>
        );
    }

    let dateFormatted = "";
    let connectionWord = "";
    let hourFormatted = "";

    switch (period) {
        case "hour":
            hourFormatted = dayjs(start).format("HH:mm");
            dateFormatted = dayjs(start).format("LL");
            connectionWord = "a las";
            break;

        case "day":
            hourFormatted = "";
            dateFormatted = dayjs(start).format("DD MMMM YYYY");
            connectionWord = "el";
            break;

        case "month_start":
            hourFormatted = "";
            dateFormatted = dayjs(start).format("DD MMMM YYYY");
            connectionWord = "el";
            break;

        default:
            break;
    }

    const energy = convertToEnergy({ value });

    return (
        <span style={{ color: enerbitColors.primary.main }}>
            <span style={{ fontWeight: "bold" }}>
                {energy.value.toFixed(1) + " " + energy.unit}
            </span>
            <span>{` ${connectionWord} `}</span>
            {hourFormatted ? (
                <span style={{ fontWeight: "bold" }}>
                    {hourFormatted}
                    <br />{" "}
                </span>
            ) : (
                <br />
            )}
            <span style={{ fontWeight: "bold" }}>{`\n${dateFormatted}`}</span>
        </span>
    );
};

export const getAvgLabelText = ({
    period,
    avgEnergySelfconsumption = 0,
    start,
    end,
}: IAvrLabelText) => {
    let dateStartFormatted = "";
    let dateEndFormatted = "";
    let sinceWord = "";
    let untilWord = "";
    let hourEndFormatted = "";

    switch (period) {
        case "hour":
            dateStartFormatted = dayjs(start).format("HH:mm");
            hourEndFormatted = dayjs(end).format("HH:mm");
            dateEndFormatted = dayjs(end).format("LL");
            sinceWord = "de las";
            untilWord = "hasta las";
            break;

        case "day":
            dateStartFormatted = dayjs(start).format("DD MMMM YYYY");
            dateEndFormatted = dayjs(end).format("DD MMMM YYYY");
            sinceWord = "desde el";
            untilWord = "hasta el";
            break;

        case "month_start":
            dateStartFormatted = dayjs(start).format("DD MMMM YYYY");
            dateEndFormatted = dayjs(end)
                .subtract(1, "days")
                .format("DD MMMM YYYY");
            sinceWord = "desde";
            untilWord = "hasta";
            break;

        default:
            break;
    }

    const energy = convertToEnergy({ value: avgEnergySelfconsumption });

    if (!start) {
        return (
            <span
                style={{
                    color: enerbitColors.primary.main,
                    fontWeight: "bold",
                }}
            >
                Selecciona un valor
            </span>
        );
    }

    return (
        <span style={{ color: enerbitColors.primary.main }}>
            <span style={{ fontWeight: "bold" }}>
                {energy.value.toFixed(1) + " " + energy.unit + " "}
            </span>
            <span>
                {period == "hour" ? (
                    <span>
                        {sinceWord}{" "}
                        <span style={{ fontWeight: "bold" }}>
                            {" "}
                            {`${dateStartFormatted}\n`}
                        </span>{" "}
                        <br />
                    </span>
                ) : (
                    <span>
                        {sinceWord} <br />{" "}
                        <span style={{ fontWeight: "bold" }}>
                            {" "}
                            {`${dateStartFormatted}\n`}
                        </span>
                    </span>
                )}
            </span>
            {period == "hour" ? (
                <span>{` ${untilWord} `}</span>
            ) : (
                <span>
                    <br />
                    {untilWord}
                    <br />
                </span>
            )}
            {hourEndFormatted && (
                <span style={{ fontWeight: "bold" }}>
                    {`${hourEndFormatted}\n`} <br />
                </span>
            )}
            <span style={{ fontWeight: "bold" }}>{dateEndFormatted}</span>
        </span>
    );
};
export const getTotalEnergyConsumed = (
    selfConsumption: number,
    activeImported: number,
) => (selfConsumption ?? 0) + (activeImported ?? 0);

export const getNetaEnergy = (
    conventionalActiveEnergyImported: number,
    conventionalActiveEnergyExported: number,
) =>
    Math.round(
        convertToEnergy({
            value:
                conventionalActiveEnergyImported -
                conventionalActiveEnergyExported,
            fixedUnit: "kWh",
        }).value,
    );

export const getPercent = (value: number, totalValue: number) =>
    Math.round((value / totalValue) * 100);

export const getPercentString = (dividend: number, divider: number) => {
    const percent = divider != 0 ? getPercent(dividend, divider) : 0;
    return percent != 0 ? ` = ${percent}%` : "";
};

export const formatNumber = (number: number) => {
    return Number.isInteger(number) ? number.toString() : number.toFixed(2);
};
