import { Box, enerbitColors } from "@enerbit/base";
import { useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { convertToEnergy } from "../../../classes/Energy";
import { ConventionCard } from "../../../common/Cards/ConventionCard";
import { EnergyCard } from "../../../common/Cards/EnergyCard";
import { ExpandDefinitions } from "../../../common/Cards/ExpandDefinitions";
import { SelectDatePickerOrRange } from "../../../common/Dates/SelectDatePickerOrRange";
import { conventions } from "../../../const/Conventions";
import { GraphicFiltersByTimestamp } from "../../../const/Graphic";
import { useGraphicsStore } from "../../../store/Graphics";
import { useSolarManager } from "../../../store/SolarManager";
import { getFirstDay, getNextDay } from "../../../utils/Date";
import {
    getPercent,
    getPercentString,
    getTotalEnergyConsumed,
} from "../../../utils/Usages";
import EnergyInfo from "./EnergyInfo";

export const SolarGraphic = () => {
    const { getUsages, stadistic_energy_usages, isLoading } =
        useGraphicsStore();
    const { solarService } = useSolarManager();

    useEffect(() => {
        getUsages({
            since: getFirstDay(),
            until: getNextDay(new Date()),
            period_str: "day",
            measurement_point_id: solarService?.measurement_point_id ?? "",
            parent_point_id: solarService?.parent_point_id ?? "",
        });
    }, []);

    const autoConsumed = Math.abs(
        stadistic_energy_usages?.energy_accumulate
            .active_energy_selfconsumption ?? 0,
    );

    const totalConsumed = Math.round(
        convertToEnergy({
            value:
                getTotalEnergyConsumed(
                    autoConsumed,
                    stadistic_energy_usages?.energy_accumulate
                        .conventional_active_energy_imported ?? 0,
                ) ?? 0,
            fixedUnit: "kWh",
        }).value,
    );

    const conventionalExported = Math.round(
        convertToEnergy({
            value:
                stadistic_energy_usages?.energy_accumulate
                    .conventional_active_energy_exported ?? 0,
            fixedUnit: "kWh",
        }).value,
    );

    const conventionalImported = Math.round(
        convertToEnergy({
            value:
                stadistic_energy_usages?.energy_accumulate
                    .conventional_active_energy_imported ?? 0,
            fixedUnit: "kWh",
        }).value,
    );

    const selfConsumption = Math.round(
        convertToEnergy({
            value: autoConsumed,
            fixedUnit: "kWh",
        }).value,
    );

    const solarGeneration = Math.round(
        convertToEnergy({
            value:
                stadistic_energy_usages?.energy_accumulate
                    .solar_active_energy_exported ?? 0,
            fixedUnit: "kWh",
        }).value,
    );

    const methods = useForm<{
        since: string | null;
        until: string | null;
    }>({
        defaultValues: {
            since: null,
            until: null,
        },
        mode: "onChange",
    });

    useEffect(() => {
        methods.setValue("since", getFirstDay());
        methods.setValue("until", getNextDay(new Date()));
    }, []);

    return (
        <>
            <Box
                sx={{
                    border: `1px solid ${enerbitColors.primary[200]}`,
                    borderRadius: "10px",
                    padding: "2rem 3rem",
                    width: "100%",
                }}
            >
                <Box>
                    <p
                        style={{
                            marginTop: "0px",
                            textAlign: "center",
                            color: enerbitColors.primary.main,
                            fontWeight: "bold",
                            fontSize: "20px",
                        }}
                    >
                        Información sobre la energía del Sistema FV
                    </p>
                </Box>
                <Box>
                    <FormProvider {...methods}>
                        <SelectDatePickerOrRange
                            period={GraphicFiltersByTimestamp.DAY}
                            name={" "}
                            label={""}
                        />
                    </FormProvider>
                    <EnergyInfo />
                </Box>
            </Box>
            <ExpandDefinitions
                body={
                    <>
                        {conventions.map((convention, index) => (
                            <ConventionCard
                                key={index}
                                title={convention.title}
                                body={convention.body}
                                color={convention.color}
                            />
                        ))}
                    </>
                }
            />
            {!isLoading && stadistic_energy_usages != null && (
                <>
                    <Box
                        sx={{ display: "flex", justifyContent: "space-evenly" }}
                    >
                        <EnergyCard
                            primaryColor={"#fa7707"}
                            secondaryColor={"#55d4b6"}
                            backgroundTitle={"#53358e"}
                            textUnderline={"Consumo total"}
                            textUnderlineNext={"de energía"}
                            textUnderlineDown={totalConsumed + " kWh = 100%"}
                            textRight={"Autoconsumida"}
                            textLeft={"Consumida de la red"}
                            textRightPercent={
                                selfConsumption +
                                " kWh = " +
                                getPercent(selfConsumption, totalConsumed) +
                                " %"
                            }
                            textLeftPercent={
                                conventionalImported +
                                " kWh = " +
                                getPercent(
                                    conventionalImported,
                                    totalConsumed,
                                ) +
                                " %"
                            }
                            percentRight={getPercent(
                                selfConsumption,
                                totalConsumed,
                            )}
                            percentLeft={getPercent(
                                conventionalImported,
                                totalConsumed,
                            )}
                        />
                        <EnergyCard
                            primaryColor={"#fa7707"}
                            secondaryColor={"#3b83f6"}
                            backgroundTitle={"#214785"}
                            textUnderline={"Generación total"}
                            textUnderlineNext={"energía solar"}
                            textUnderlineDown={solarGeneration + " kWh = 100%"}
                            textRight={"Autoconsumida"}
                            textLeft={"Exportada a la red"}
                            textRightPercent={
                                selfConsumption +
                                " kWh" +
                                getPercentString(
                                    selfConsumption,
                                    solarGeneration,
                                )
                            }
                            textLeftPercent={
                                conventionalExported +
                                " kWh" +
                                getPercentString(
                                    conventionalExported,
                                    solarGeneration,
                                )
                            }
                            percentRight={getPercent(
                                selfConsumption,
                                solarGeneration,
                            )}
                            percentLeft={getPercent(
                                conventionalExported,
                                solarGeneration,
                            )}
                        />
                    </Box>
                    <Box sx={{ display: "flex", justifyContent: "center" }}>
                        <EnergyCard
                            primaryColor={"#2ebe91"}
                            secondaryColor={"#3b82f6"}
                            backgroundTitle={"#163059"}
                            textUnderline={"Energía neta"}
                            textUnderlineNext={"del sistema"}
                            textUnderlineDown={
                                conventionalImported +
                                " kWh - " +
                                conventionalExported +
                                " kWh = " +
                                (conventionalImported - conventionalExported) +
                                " kWh"
                            }
                            textRight={"Importada de la red"}
                            textLeft={"Exportada a la red"}
                            textRightPercent={
                                conventionalImported +
                                " kWh" +
                                getPercentString(
                                    selfConsumption,
                                    solarGeneration,
                                )
                            }
                            textLeftPercent={
                                conventionalExported +
                                " kWh" +
                                getPercentString(
                                    conventionalExported,
                                    solarGeneration,
                                )
                            }
                            percentRight={getPercent(
                                selfConsumption,
                                solarGeneration,
                            )}
                            percentLeft={getPercent(
                                conventionalExported,
                                solarGeneration,
                            )}
                        />
                    </Box>
                </>
            )}
        </>
    );
};
