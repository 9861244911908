import { NotiProvider } from "@enerbit/base";
import LeftMenu from "../components/Menu/LeftMenu";

const SolarMonitoringPage = () => {
    return (
        <NotiProvider>
            <LeftMenu />
        </NotiProvider>
    );
};

export default SolarMonitoringPage;
